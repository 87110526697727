  import React, {useContext, useState, useEffect} from "react";
  import { makeStyles } from "@material-ui/core/styles";
  import CircularProgress from "@material-ui/core/CircularProgress";
  import Paper from "@material-ui/core/Paper";
  import Tabs from "@material-ui/core/Tabs";
  import Tab from "@material-ui/core/Tab";
  import { API } from 'aws-amplify';
  
  import Slate from './Slate';
  import DXTable from "../components/DxConfigTable";
  import { DxContext } from "../contexts/DxContextProvider";

  import styles from "../styles.module.css";

  const tableHeaders = {
    'name' : 'Name',
    'aws_account' : 'AWS Account',
    //'aws_account_id' : 'AWS Account ID',
    'aws_region' : 'AWS Region',
    'dc_name': 'DC Name',
    'logical-connection-flow' : 'Logical Connection Flow',
    'fiber-vendor' : 'Fiber Vendor',
    'circuit-id' : 'Circuit ID',
    'dx-id' : 'DX ID',
    'dxcon_location' : 'DX Location',
    'dxcon_owner' : 'DX Owner',
    'dxcon_portSpeed' : 'DX Port Speed',
    'dxcon_status' : 'DX Status',
    'vifs' : 'VIFs',
    'vlan' : 'VLAN',
    'region': 'Region',
    'amazon_dxgw_asn' : 'AWS DXGW ASN',
    'amazon_txgw_asn' : 'AWS TXGW ASN',
    'target' : 'Target',
    'event_tenant': 'Event Tenant',
    'fts_tenant': 'FTS Tenant',
    '_created': '_created',
    '_user': '_user',
    '_version' : '_version',
    //'comment' : 'Comment',
  }
  
  const dcNames = {
    'MX1SES_HAW' : 'mx1-haw',
    'EDM_ATL' : 'edm-atl',
    'EDM_STA' : 'edm-sta',
    'HAL_NEW' : 'halsey',
    'SOFTBANK_TKY': 'jp-nrt',
    'EDM_LHR' : 'edm-london',
    'IMG_LHR' : 'img-london',
    'SWITCH_LAX' : 'switch-lax',
    'NBC_EWC' : 'nbc-ewc',
    '3ZERO2' : '3zero2',
    'GLOBO' : 'globo',
    'HELBIZIT' : 'HELBIZIT',
    'MEDIACHOICE' : 'MEDIACHOICE',
    'OLPLAY' : 'OLPLAY',
    'SALTO' : 'SALTO',
    'SES_MUNICH' : 'SES_MUNICH',
    'SPI' : 'spi',
    'SPEIGELGESCHICTE' : 'SPEIGELGESCHICTE',
    'TELSTRA' : 'TELSTRA',
    'MISC' : 'misc'
  }
  
  const awsRegions = {
    'IAD' : 'IAD',
    'PDX' : 'PDX',
    'SFO' : 'SFO',
    'DUB' : 'DUB',
    'LHR' : 'LHR',
    'FRA' : 'FRA',
    'NRT' : 'NRT',
    'SIN' : 'SIN',
    'BOM' : 'BOM',
    'GRU' : 'GRU',
    'MISC' : 'misc'
  }
  
  
  /** 
   * Flatten nested object for given object
   *  @param {string} obj - Nested object that needs to be flattened
  */
  const flattenDbObject = (obj) => {
    const toReturn = {};
    
    for (const i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      
      if ((typeof obj[i]) == 'object') {
        const flatObject = flattenDbObject(obj[i]);
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;
          
          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = obj[i];
      }
    }
    return toReturn;
  };
  
  /** 
   * Tab panel component
   *  @param {string} props - includes row, columns, unique index
  */
  function TabPanel(props) {
    const { children, value, index, rows, columns, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className={styles.table}>
            <DXTable
              rows={rows}
              columns={columns}
            />
          </div>
        )}
      </div>
    );
  }
  
  /** 
   * Tabs props feeder
   *  @param {string} index - unique index for each tab
  */
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }
  
  /** 
   * Styling construct / wrapper used by MUI components
   *  @param {string} theme - unique index for each tab
  */
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      overflow: 'hidden',
      width: '100%'
    },
    scrollableX: {
      flexGrow: 0,
      overflow: 'hidden',
      width: '100%'
    }
  }));

  /** 
   * Sort object elements based on key
   *  @param {string} first - first element to be sorted
   *  @param {string} second - second element to be sorted
   *  @param {string} key - key  with which element are to be sorted
  */
  const sortByKey = (first, second, key) => {
    if (first[key] < second[key]) return -1;
    if (first[key] > second[key]) return 1;
    return 0
  }

  /** 
   * Hardware config DB page within Dante
   *  @param {string} props - nothing passed at this time
  */
  export default function DxConfigSelector(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [dbData, setDbData] = useState([]);

    const [ dxState, dxDispatch] = useContext(DxContext);
    
    useEffect(() => {
      const path = "dx_management/view/allDxConfig";
      API.get(
        process.env.REACT_APP_API_ENVIRONMENT, 
        path, {
          headers: {
            'Cache-Control': 'no-cache',
            'Content-type': 'application/json',
          },
          queryStringParameters: {
            action : "view_all_dxconfig",
            config_type : "dxconfig",
          }
      })
      .then(response => {
        // Flatten nested response
        let rowData = [];
        for (const [key, obj] of Object.entries(response)){ 
          rowData.push(flattenDbObject(obj));
        }
        rowData.sort( (a,b) => sortByKey(a,b, 'name'));
        setDbData(rowData);
        dxDispatch({ type: 'SET_DB_DATA_RECEIVED', dbDataReceived: true });
      })
      .catch(error => {
        console.log(error)
        console.log(error.response);
      });
    }, []);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    /** 
     * Check for duplicate rows and returns Boolean on match
     *  @param {string} dbData -  DB data state
     *  @param {string} newRowDataName - row to be added
    */
     const validateRowData = ( key, newRowData )=> {
      if (key === 'name'){
        if (newRowData.name === undefined || newRowData.name === ''){
          return { isValid: false, helperText: `Name can not be blank` };
        }
      }
      if (key === 'dc_name'){
        if (newRowData.dc_name === undefined || newRowData.dc_name === ''){
          return { isValid: false, helperText: `DC Name can not be blank` };
        }
      }
      if (key === 'aws_region'){
        if (newRowData.aws_region === undefined || newRowData.aws_region === ''){
          return { isValid: false, helperText: `AWS Region can not be blank` };
        }
      }
      return { isValid: true};
   }

    const columnData =  []; 
    for (const [ key, value] of Object.entries(tableHeaders)){ 
      columnData.push({
        validate: rowData => validateRowData(key, rowData),
        field: key,
        title: value,
        editable: (key === '_user' || key === '_version' || key === '_created') ? 'never' : 'always',
        cellStyle: {
          whiteSpace: 'nowrap',
          padding: '10px'
        }
      })
    }
    
    return  (
      <React.Fragment>
        {dxState.dbDataReceived ? 
          <div className={classes.root}>
            <Paper>
              <Tabs
                classes={{ scrollableX: classes.scrollableX }}
                //variant="standard"
                value={value}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
                wrapped={true}
              >
                {
                  Object.values(dcNames).map((tab, key) => {
                  //Object.values(awsRegions).map((tab, key) => {
                    return <Tab label={tab} {...a11yProps(key)} key={key} />;
                  })
                }
              </Tabs>
            </Paper>
            {
              Object.keys(dcNames).map((location, key) => {
              //Object.keys(awsRegions).map((location, key) => {
                return (
                  location === 'MISC' ?  
                    <TabPanel
                      value={value}
                      index={key}
                      key={key}
                      columns={columnData}
                      //rows={dbData.filter( item => !(item.aws_region in awsRegions) )}
                      rows={dbData.filter( item => !(item.dc_name in dcNames) )}
                    /> : 
                    <TabPanel
                      value={value}
                      index={key}
                      key={key}
                      columns={columnData}
                      //rows={dbData.filter( item => item.aws_region === location)}
                      rows={dbData.filter( item => item.dc_name === location)}
                    />
                    
                )
              })
            }
          </div>
        : 
          <Slate>
            <CircularProgress size={48} className={classes.buttonProgress} color="secondary" />
            <h2>Loading DB Data...</h2>
          </Slate>
        }
      </React.Fragment>
    );
  }
